import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonail_Section = () => {
  const settings = {
    nav:true,
    dots: false,
    margin: 50,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
    ]
  };

  return (
    <>
      <section className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <h3 className="montfont mt-5">Clients’ Love towards Webions </h3>
              <p>Our Clients Trust Us and Empower Us to Shine More </p>
            </div>
          </div>
          <Slider {...settings}>
            <div className="col-md-12">
              <div className="testi">
                <div className="testi-img">
                  <img src="images/testi-17.png" />
                  <h4>Epic Hockey</h4>
                  <p>
                    Webions has become an extended part of our team. We have
                    been working with them regularly now for various projects
                    and just love their work especially explainer videos.
                  </p>
                </div>
                <div className="testi-desc">
                  <ul>
                    <li>Date: 15-01-2022</li>
                    <li className="t-center">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star-half-o"></i> 4.5/5
                    </li>
                    <li className="t-right">
                      <i className="verify"></i>Verfied
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="testi">
                <div className="testi-img">
                  <img src="images/testi-6.png" />
                  <h4>Versatao</h4>
                  <p>
                    The designers and service agents are extremely talented and
                    professional. The creativity impressed immensely. With the
                    competitive prices they are offering, I highly recommend
                    them.
                  </p>
                </div>
                <div className="testi-desc">
                  <ul>
                    <li>Date: 11-02-2022</li>
                    <li className="t-center">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i> 5/5
                    </li>
                    <li className="t-right">
                      <i className="verify"></i>Verfied
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="testi">
                <div className="testi-img">
                  <img src="images/testi-7.png" />
                  <h4>Huskies</h4>
                  <p>
                    Beginning a business isn’t the biggest challenge, but
                    getting it recognised it in the industry definitely is! I
                    firmly believe that Webions the business logo design company
                    that has reached to a level in no time.{" "}
                  </p>
                </div>
                <div className="testi-desc">
                  <ul>
                    <li>Date: 18-02-2021</li>
                    <li className="t-center">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star-o"></i> 4/5
                    </li>
                    <li className="t-right">
                      <i className="verify"></i>Verfied
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="testi">
                <div className="testi-img">
                  <img src="images/testi-17.png" />
                  <h4>Epic Hockey</h4>
                  <p>
                    Webions has become an extended part of our team. We have
                    been working with them regularly now for various projects
                    and just love their work especially explainer videos.
                  </p>
                </div>
                <div className="testi-desc">
                  <ul>
                    <li>Date: 15-01-2022</li>
                    <li className="t-center">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star-half-o"></i> 4.5/5
                    </li>
                    <li className="t-right">
                      <i className="verify"></i>Verfied
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="testi">
                <div className="testi-img">
                  <img src="images/testi-6.png" />
                  <h4>Versatao</h4>
                  <p>
                    The designers and service agents are extremely talented and
                    professional. The creativity impressed immensely. With the
                    competitive prices they are offering, I highly recommend
                    them.
                  </p>
                </div>
                <div className="testi-desc">
                  <ul>
                    <li>Date: 11-02-2022</li>
                    <li className="t-center">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i> 5/5
                    </li>
                    <li className="t-right">
                      <i className="verify"></i>Verfied
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="testi">
                <div className="testi-img">
                  <img src="images/testi-7.png" />
                  <h4>Huskies</h4>
                  <p>
                    Beginning a business isn’t the biggest challenge, but
                    getting it recognised it in the industry definitely is! I
                    firmly believe that Webions the business logo design company
                    that has reached to a level in no time.{" "}
                  </p>
                </div>
                <div className="testi-desc">
                  <ul>
                    <li>Date: 18-02-2021</li>
                    <li className="t-center">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star-o"></i> 4/5
                    </li>
                    <li className="t-right">
                      <i className="verify"></i>Verfied
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Slider>
          </div>
      </section>
    </>
  );
};
export default Testimonail_Section;

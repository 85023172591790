import React, { useState } from 'react'
import Modal from './Modal2'
import { NavLink, useNavigate } from 'react-router-dom';
import { scroller } from "react-scroll";



export default function Review_client() {
   const [active,setActive] = useState(false)
   const navigate = useNavigate();
  
   const scrollTo = (ref, id) => {
     navigate(ref);
     setTimeout(() => {
       scroller.scrollTo(id, {
         spy: true,
         smooth: true,
         offset: 50,
         duration: 500,
       });
     }, 100);
   };
   
  return (
    <>
    <div className='testimo' id="top">
     <section class="bannermm">
         <div class="container">
            <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="leftcont-ban">
                     <h1 class="montfont"><span>Look What Our </span> <br/><strong>Valued Clients</strong> Have To Say About Us!</h1>
                     <p>Thousand of clients share their love for Webions</p>
                     <a class="montfont fixedfomrclick popup-open" href="javascript:;" title="">Start Your Project <i class="sprite btnarow"></i></a>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
      <section class="brdcrme">
         <div class="container">
            <div class="row">
               <div class="col-lg-12 col-md-12">
                  <div class="content-incred">
                     <ul>
                     <li><NavLink to="/" >Home</NavLink></li>
                        <li><NavLink to="/Review">Reviews</NavLink></li>
                        
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="testimonials">
   <div class="container">
      <div class="row">
         <h3 class="montfont">Clients’ Love towards Webions </h3>
         <p>Our Clients Trust Us and Empower Us to Shine More </p>
      </div>
      <div class="row testi-main  slidertest-mm">
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-17.png"/>
                  <h4>Epic Hockey</h4>
                  <p>Webions has become an extended part of our team. We have been working with them regularly now for various projects
                     and just love their work especially explainer videos.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 15-01-2022</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-half-o"></i> 4.5/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-6.png"/>
                  <h4>Versatao</h4>
                  <p>The designers and service agents are extremely talented and professional. The creativity impressed immensely. With
                     the competitive prices they are offering, I highly recommend them.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 11-02-2022</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i> 5/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-7.png"/>
                  <h4>Huskies</h4>
                  <p>Beginning a business isn’t the biggest challenge, but getting it recognised it in the industry definitely is! I firmly
                     believe that Webions the business logo design company that has reached to a level in no time. </p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 18-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-8.png"/>
                  <h4>MPi Risk</h4>
                  <p>From last many years, I have been doing business with Webions and they are my favourite, I appointed them, for my
                     first project and trust me, I have never regretted it. Ever since I have never worked with any other company. Highly
                     recommended.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 13-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-9.png"/>
                  <h4>Freddie's</h4>
                  <p>Webions converted my idea into reality. I am absolutely thrilled and glad with the results. Their team definitely
                     surpassed my anticipations and I am sure we will work with them in future too.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 09-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-10.png"/>
                  <h4>Neples Property Preview</h4>
                  <p>They are extremely accommodating and considerate. Their prompt delivery process, remarkable service and reasonable
                     prices have acquired us as permanent clients.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 06-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/logoTesti-1.png"/>
                  <h4>Global Tech</h4>
                  <p>We received the best possible services by Webions in a long time. They were extremely helpful and professional, and the entire procedure of designing my logo was forthright and intuitive. I am glad I chose them.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 26-03-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-half-o"></i> 4.5/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/logoTesti-2.png"/>
                  <h4>Simple Treasures</h4>
                  <p>The designers and service agents are extremely talented and professional. The creativity impressed immensely. With the competitive prices they are offering, I highly recommend them.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 24-03-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i> 5/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/logoTesti-3.png"/>
                  <h4>Lunch Rate</h4>
                  <p>Beginning a business isn’t the biggest challenge, but getting it recognised it in the industry definitely is! I firmly believe that Webions the business logo design company that has reached to a level in no time.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 21-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/logoTesti-4.png"/>
                  <h4>Mountain Ridge</h4>
                  <p>From last many years, I have been doing business with Webions and they are my favourite, I appointed them, for my first project and trust me, I have never regretted it. Ever since I have never worked with any other company. Highly recommended.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 16-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/logoTesti-5.png"/>
                  <h4>Julian Tucker</h4>
                  <p>Webions converted my idea into reality. I am absolutely thrilled and glad with the results. Their team definitely surpassed my anticipations and I am sure we will work with them in future too.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 13-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/logoTesti-6.png"/>
                  <h4>Game Stamps</h4>
                  <p>They are extremely accommodating and considerate. Their prompt delivery process, remarkable service and reasonable prices have acquired us as permanent clients.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 10-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-11.png"/>
                  <h4>DBoylan</h4>
                  <p>We are extremely happy with our website revamp. We wanted it to be mobile responsive and the results are just amazing.
                     Their team made sure that the website is according to our demands and the latest trends. Glad to choose Webions .</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 07-03-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-half-o"></i> 4.5/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-12.png"/>
                  <h4>Krystal Vision</h4>
                  <p>I am very satisfied with the well-organized and neat layout of my website. All my concerns were dealt with perfection
                     and the communication was smooth and well-comprehended.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 12-03-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i> 5/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-13.png"/>
                  <h4>Horizon Cleaning inc</h4>
                  <p>My experience with Webions has been a memorable one. Their team is very thorough and highly professional. From the
                     account manager to the designers, everyone was fantastic. Highly recommended.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 23-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-14.png"/>
                  <h4>Faryn Construction</h4>
                  <p>Their service is seamless and prompt. They listened to my every demand and understood what I wanted right away. The finished
                     product was very well done.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 22-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-15.png"/>
                  <h4>Proin Entory</h4>
                  <p>An exceptional service from beginning till end. The account manager was extremely cooperative and his team was vigilant
                     to respond to the given feedback.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 21-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-16.png"/>
                  <h4>Ravelo</h4>
                  <p>I got to know about Webions while internet search and they promptly responded to my message. They showed me three
                     prototypes and the final version was just brilliant.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 19-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>

         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-23.png"/>
                  <h4>Orange Flame</h4>
                  <p>I got connected with Webions when I was in dire need of a rebranding of my business. Within a limited time frame, they provided me all the required products and their price range is awesome. All the concepts they showed were amazing.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 18-01-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-half-o"></i> 4.5/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-24.png"/>
                  <h4>Sanatos</h4>
                  <p>I have been working with Webions for many years now and have never faced disappointment from their designs. They are extremely professional, experts in designing with unparalleled methodologies. Their process is always perfectly streamlined. Highly recommended.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 27-01-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i> 5/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-25.png"/>
                  <h4>Cabin in the Forest</h4>
                  <p>From logo designing to corporate branding, Webions is highly professional and skilled. They surpassed my expectations with their meticulous stationery designs. Keep up the amazing work!</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 17-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-26.png"/>
                  <h4>Darren Turner</h4>
                  <p>The team at Webions has been extremely patient and professional while doing my corporate branding. Anybody who needs to get a website, business card or a brochure, they are highly suggested.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 24-02-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-27.png"/>
                  <h4>Oakworth</h4>
                  <p>The amazing attitude by the team must be immensely appreciated. They have shown a perfectly professional attitude while designing my brochures. Their creativity is surpassed. I just adored working with them.</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 07-03-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-4">
            <div class="testi">
               <div class="testi-img">
                  <img src="images/testi-28.png"/>
                  <h4>Codeworx</h4>
                  <p>The fact that I adulate about Webions is their enthusiasm regarding their work. Their professionalism and passion for work shows in the products they produce. Great job!</p>
               </div>
               <div class="testi-desc">
                  <ul>
                     <li>Date: 12-03-2021</li>
                     <li class="t-center">
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star"></i>
                        <i class="fa fa-star-o"></i> 4/5
                     </li>
                     <li class="t-right">
                        <i class="verify"></i>Verfied
                     </li>
                  </ul>
               </div>
            </div>
         </div>

      </div>
      <div class="row packagesall">
         <div class="col-md-12 portfolio-mmbx">
            <div class="leftcont-ban testi-a">
               <a class="montfont" href="reviews/index.html" title="">View All <i class="sprite btnarow"></i></a>
            </div>
         </div>
         <div class="col-md-12 trustpilot-class d-none">
            <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5f7f791e63445e0001170c43" data-style-height="24px" data-style-width="100%" data-theme="light" data-min-review-count="20">
            <a href="https://www.trustpilot.com/review/digitdesignstudios.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
         </div>
      </div>
   </div>
</section>
      <section class="postReviws">
         <div class="container">
            <div class="row">
               <div class="col-12">
               <form  id ="frm" class="reviewForm" enctype="multipart/form-data" action="javascript:void(0)" method="post">
                  <div class="form-group">
                     <label for="textArea">Give Us Review</label>
                     <textarea class="form-control" name="Review" id="Review" rows="5"></textarea>
                  </div>
                  <div class="formfoot d-flex">
                     <button class="btn btn-primary reviewBtn" id="btnSubmit" type="button">Submit</button>
                     <div id="reviewLoader"></div>
                  </div>
                  <div id="reviewResult"></div>
               </form>
               </div>
            </div>
         </div>
      </section>
	  
      <section class="maincta" data-img="url(images/ctabg.jpg) ">
         <div class="container">
            <div class="row">
               <div class="col-lg-5 col-md-6 col-sm-7 padright0">
                  <h3 class="montfont">Together we create a <strong>Unique Digital Entity!</strong> </h3>
                  <p class="montfont">Contact our experts NOW to begin crafting your project.</p>
               </div>
               <div class="col-lg-7 col-md-6 col-sm-5 padright0">
                  <div class="btn-radius-top">
                     <a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Packages"),
                                  (id = "pricing")
                                )
                              } title="">Select Package</a>
                     <a  onClick={()=>setActive(true)} class="popup-open" href="javascript:;" title=""><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Request Custom Quote</a>
                  </div>
                  <div class="callsdivs montfont"><i class="sprite iconphone"></i>
                     <a href="tel:+1 (866) 726 3004" title="">Local Number: +1 (866) 726 3004</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <Modal active={active} setActive={setActive}/>
      
    </>
  )
}
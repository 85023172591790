import React, { useState } from 'react'
import Modal from './Modal2'
import { scroller } from "react-scroll";
import { NavLink, useNavigate } from 'react-router-dom';

export default function Annimation() {
   
   
   const [active,setActive] = useState(false)
   const navigate = useNavigate();
  
   const scrollTo = (ref, id) => {
     navigate(ref);
     setTimeout(() => {
       scroller.scrollTo(id, {
         spy: true,
         smooth: true,
         offset: 50,
         duration: 500,
       });
     }, 100);
   };
 
  
  return (
    <>
    <div className='animation'>
        <section class="animation bannermm">
         <div class="container">
            <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="leftcont-ban">
                     <h1 class="montfont"><span>Illustrate Your </span> <br/><strong>Brand's Story,</strong> With Remarkable Animations</h1>
                     <p>Spread your message through the most effective medium.</p>
                     <a class="montfont poundbtn animbtnsstart popup-open" onClick={()=>setActive(true)}  title="">Start Your Animation Project from $499 <i class="sprite btnarow"></i></a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <Modal active={active} setActive={setActive}/>
    </div>
      <section class="brdcrme">
         <div class="container">
            <div class="row">
               <div class="col-lg-12 col-md-12">
                  <div class="content-incred">
                     <ul>
                        <li><NavLink to="/" >Home</NavLink></li>
                        <li><NavLink to="/Animation_design">Animation Design</NavLink></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="incred-expe" id="section08">
         <div class="container fullslider">
            <div class="row">
               <div class="slides-mm">
                  <div class="col-lg-7 col-md-7">
                     <div class="content-incred">
                        <h5 class="montfont fbold f24">We passionately spread your story in an engaging way</h5>
                        <h3 class="montfont f30">We create videos that will make your story an unforgettable experience</h3>
                        <p>Webions crafts animated videos, motion graphics and explainer videos that effectively communicate diverse and difficult massages to your audience without a complicated story line. We spread your brand story in a more captivating way that maximizes the number of audiences. Our 2D and 3D animated videos inspire your audience that will help your business growth, increasing the annual revenue and number of clients. </p>
                        <p>Our specialized team of graphic designers, animators and script writers competently design and produce every frame ensuring that all the elements are put together to create an unmatchable end product. </p>
                     </div>
                  </div>
                  <div class="col-lg-5 col-md-5 md-hidden">
                     <img src="images/animation-1.png" alt=""/>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="animation-service">
         <div class="container">
            <div class="row responsive-slider-1">
               <div class="col-md-6">
                  <div class="srvc-box">
                     <div class="srvc-lft">
                        <a href="images/gif/Bdfi.gif" title="">
                           {/* <!-- <img class="gif-img" src="images/gif/normalgif1.gif" alt=""> --/> */}
                           <img class="gif-img" src="images/gif/Bdfi.gif" alt=""/>
                        </a>
                     </div>
                     <div class="srvc-rht">
                        <h4 class="montfont">2D Character Animation</h4>
                        <p>We have the best concept and character artists that conceive believable characters to convey your message persuasively.</p>
                        <a href="2D-character-animation.html">Explore <i class="fa fa-angle-double-right"></i></a>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="srvc-box">
                     <div class="srvc-lft">
                        <img src="images/gif/A448.gif"/>
                     </div>
                     <div class="srvc-rht">
                        <h4 class="montfont">2D Graphic Animation </h4>
                        <p>The graphic designers at Webions are extremely creative and have produced some of the best 2D graphic animations.</p>
                        <a href="2D-graphic-animation.html">Explore <i class="fa fa-angle-double-right"></i></a>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="srvc-box">
                     <div class="srvc-lft">
                        <img src="images/gif/3dani.gif"/>
                     </div>
                     <div class="srvc-rht">
                        <h4 class="montfont">Interactive 3D Animation</h4>
                        <p>We produce 3D animations that are immensely interactive and captivating to impulsively attract your target audience.</p>
                        <a href="Interactive-3D-animation.html">Explore <i class="fa fa-angle-double-right"></i></a>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="srvc-box">
                     <div class="srvc-lft">
                        <img src="images/gif/whtaniton.gif"/>
                     </div>
                     <div class="srvc-rht">
                        <h4 class="montfont">Whiteboard Animation</h4>
                        <p>The neatest and sleek whiteboard animations are created at Webions simply spreading your message to the masses.</p>
                        <a href="whiteboard-animation.html">Explore <i class="fa fa-angle-double-right"></i></a>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="srvc-box">
                     <div class="srvc-lft">
                        <img src="images/gif/typography.gif"/>
                     </div>
                     <div class="srvc-rht">
                        <h4 class="montfont">Typographic Animation</h4>
                        <p>To create a memorable impact on your audience, typographic animation will serve to be the best and most effective option.</p>
                        <a href="typographic-animation.html">Explore <i class="fa fa-angle-double-right"></i></a>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="srvc-box">
                     <div class="srvc-lft">
                        <img src="images/gif/explnr-vd.gif"/>
                     </div>
                     <div class="srvc-rht">
                        <h4 class="montfont">Explainer Videos</h4>
                        <p>When you introduce a new product, our explainer videos will make it easy for your audience to understand your product or services.</p>
                        <a href="explainer-videos.html">Explore <i class="fa fa-angle-double-right"></i></a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="homeport-mm" id='animdropsPort'>
         <div class="container">
            <div class="sechead">
               <h3 class="montfont">Take a Sneak Peek at our Portfolio</h3>
               <p>We take immense pride for the associations we created with some prestigious names through our outstanding designs.</p>
            </div>
         </div>
         <div class="portfoliomaindivs tabsmainport">
            <div class="topbartabs">
               <div id="All" class="tabs-portfolio innertabcontents active">
                  <div class="portfolio-mmbx">
                     <div id="innerall2" class="innertabcontents active">
                        <div class="row">
                           <div class="col-md-12">
                              <div class="row responsive-slider">
                                 <div class="col-md-3">
                                    <div class="box-ports">
                                       <img data-src="images/portfolio/animation/Antonio.jpg" alt=""/>
                                       <div class="hovereffects-det">
                                          <h3>United Medical Alert</h3>
                                          <div class="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/01.mp4" title="">Preview <i class="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="box-ports">
                                       <img data-src="images/portfolio/animation/Broadway.jpg" alt=""/>
                                       <div class="hovereffects-det">
                                          <h3>BroadWay market <br/>Corned Beef</h3>
                                          <div class="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/02.mp4" title="">Preview <i class="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="box-ports">
                                       <img data-src="images/portfolio/animation/Cab.jpg" alt=""/>
                                       <div class="hovereffects-det">
                                          <h3>Ice Cream</h3>
                                          <div class="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/03.mp4" title="">Preview <i class="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="box-ports">
                                       <img data-src="images/portfolio/animation/CAB--Get-Along.jpg" alt=""/>
                                       <div class="hovereffects-det">
                                          <h3>Get along with each other</h3>
                                          <div class="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/04.mp4" title="">Preview <i class="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="box-ports">
                                       <img data-src="images/portfolio/animation/Camco.jpg" alt=""/>
                                       <div class="hovereffects-det">
                                          <h3>Immersive Reality</h3>
                                          <div class="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/05.mp4" title="">Preview <i class="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="box-ports">
                                       <img data-src="images/portfolio/animation/Carmel.jpg" alt=""/>
                                       <div class="hovereffects-det">
                                          <h3>Birthday parties</h3>
                                          <div class="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/06.mp4" title="">Preview <i class="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="box-ports">
                                       <img data-src="images/portfolio/animation/christina.jpg" alt=""/>
                                       <div class="hovereffects-det">
                                          <h3>Fibre King Engineering</h3>
                                          <div class="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/07.mp4" title="">Preview <i class="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3">
                                    <div class="box-ports">
                                       <img data-src="images/portfolio/animation/COMIC_final.jpg" alt=""/>
                                       <div class="hovereffects-det">
                                          <h3>The Block Party Podcast</h3>
                                          <div class="btnstyles-nn">
                                             <a data-fancybox="animation" href="https://digitdesignstudios.com/video-portfolio/08.mp4" title="">Preview <i class="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="container leftcont-ban">
                        <a class="montfont" href="portfolio.html" title="">View All Projects <i class="sprite btnarow"></i></a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="det-packages"  id="animation_pricing">
         <div class="container">
            <div class="sechead">
               <h3 class="montfont">Affordable Packages Crafted As Per The Needs Of Your Business </h3>
               <p>Webions offers the most cost-friendly packages especially designed in accordance with your requirements.</p>
            </div>
            <div class="packagesbxs">
               <div class="row justify-content-center">
			   	  <div id="pack1" class="tabscontent responsive-slider">
                                                                              <div class="col-md-3">
                                 <div class="boxpackages" data-package-box>
   <div class="packheads">
         <h3 class="montfont">Teaser</h3>
         <p style={{display: "none"}}>Ideal for beginners and start-ups</p>
   </div>
   <div class="packdetails">
      <div class="packtitles">
         <div class="fleft">
            <h3 class="opensansfont">$499               <sub class="">60% off</sub>
            </h3>
         </div>
         <div class="fright">
            <h4>WAS
               <b>
               <span class="cutprice">$1000</span>
               </b>
            </h4>
         </div>
      </div>
      <div class="scroll listpacks" data-package-scroll>
         <ul>
<li>30sec Duration</li>
<li>Script Writing</li>
<li>Custom Artwork</li>
<li>Background Music</li>
<li>HD Format Video</li>
<li>Dedicated Support </li>
<li>100% Ownership Rights</li>
<li>100% Money-back Guarantee</li>
</ul>      </div>
      <div class="botarea-pack">
         <div class="fleft">
            <a class="montfont btn-orders order-package" href="javascript:;" data-sku="ANIMATION_TEASER"
               data-promotion-id="0" 
               data-price="499"
               data-price-text="$499.00"
               data-title="Teaser" data-package-id="2804">Order Now</a>
         </div>
         <div class="fright">
            <div class="liovechats-bx">
               <a href="javascript:;" title="" onclick="setButtonURL();">
                  <span>Click here to</span>
                  <h5 class="montfont" onclick="setButtonURL();">Live Chat</h5>
               </a>
            </div>
         </div>
      </div>
   </div>
</div>                              </div>
                                                                                       <div class="col-md-3">
                                 <div class="boxpackages" data-package-box>
   <div class="packheads">
         <h3 class="montfont">Startup</h3>
         <p style={{display: "none"}}>Ideal for beginners and start-ups</p>
   </div>
   <div class="packdetails">
      <div class="packtitles">
         <div class="fleft">
            <h3 class="opensansfont">$799               <sub class="">60% off</sub>
            </h3>
         </div>
         <div class="fright">
            <h4>WAS
               <b>
               <span class="cutprice">$1650</span>
               </b>
            </h4>
         </div>
      </div>
      <div class="scroll listpacks" data-package-scroll>
         <ul>
<li>30sec Duration</li>
<li>Script Writing</li>
<li>Professional Voice-over &amp; SFX </li>
<li>Hand-drawn Illustrations</li>
<li>Unlimited Revisions</li>
<li>HD Format Video</li>
<li>Dedicated Support</li>
<li>100% Ownership Rights</li>
<li>100% Money-back Guarantee</li>
</ul>      </div>
      <div class="botarea-pack">
         <div class="fleft">
            <a class="montfont btn-orders order-package" href="javascript:;" data-sku="ANIMATION_STARTUP"
               data-promotion-id="0" 
               data-price="799"
               data-price-text="$799.00"
               data-title="Startup" data-package-id="2805">Order Now</a>
         </div>
         <div class="fright">
            <div class="liovechats-bx">
               <a href="javascript:;" title="" onclick="setButtonURL();">
                  <span>Click here to</span>
                  <h5 class="montfont" onclick="setButtonURL();">Live Chat</h5>
               </a>
            </div>
         </div>
      </div>
   </div>
</div>                              </div>
                                                                                       <div class="col-md-3">
                                 <div class="boxpackages" data-package-box>
   <div class="packheads">
         <h3 class="montfont">Classic</h3>
         <p style={{display: "none"}}>Ideal for beginners and start-ups</p>
   </div>
   <div class="packdetails">
      <div class="packtitles">
         <div class="fleft">
            <h3 class="opensansfont">$999               <sub class="">60% off</sub>
            </h3>
         </div>
         <div class="fright">
            <h4>WAS
               <b>
               <span class="cutprice">$2500</span>
               </b>
            </h4>
         </div>
      </div>
      <div class="scroll listpacks" data-package-scroll>
         <ul>
<li>30sec Duration</li>
<li>Script Writing</li>
<li>Professional Voice-over &amp; SFX </li>
<li>Custom 2D Characters</li>
<li>Unlimited Revisions</li>
<li>HD Format Video</li>
<li>Dedicated Support</li>
<li>100% Ownership Rights</li>
<li>100% Money-back Guarantee </li>
</ul>      </div>
      <div class="botarea-pack">
         <div class="fleft">
            <a class="montfont btn-orders order-package" href="javascript:;" data-sku="ANIMATION_CLASSIC"
               data-promotion-id="0" 
               data-price="999"
               data-price-text="$999.00"
               data-title="Classic" data-package-id="2806">Order Now</a>
         </div>
         <div class="fright">
            <div class="liovechats-bx">
               <a href="javascript:;" title="" onclick="setButtonURL();">
                  <span>Click here to</span>
                  <h5 class="montfont" onclick="setButtonURL();">Live Chat</h5>
               </a>
            </div>
         </div>
      </div>
   </div>
</div>                              </div>
                                                                                       <div class="col-md-3">
                                 <div class="boxpackages" data-package-box>
   <div class="packheads">
         <h3 class="montfont">3D Video</h3>
         <p style={{display: "none"}}>Ideal for beginners and start-ups</p>
   </div>
   <div class="packdetails">
      <div class="packtitles">
         <div class="fleft">
            <h3 class="opensansfont">$3999               <sub class="">60% off</sub>
            </h3>
         </div>
         <div class="fright">
            <h4>WAS
               <b>
               <span class="cutprice">$5500</span>
               </b>
            </h4>
         </div>
      </div>
      <div class="scroll listpacks" data-package-scroll>
         <ul>
<li>2 3D Object Models</li>
<li>Unlimited Storyboard Revisions</li>
<li>Professional Voice - Over</li>
<li>Background Music</li>
<li>Exotic Animations</li>
<li>HD Video Format</li>
<li>6 Weeks Delivery Time</li>
<li>100% Ownership Rights</li>
<li>100% Money-back Guarantee </li>
</ul>      </div>
      <div class="botarea-pack">
         <div class="fleft">
            <a class="montfont btn-orders order-package" href="javascript:;" data-sku="ANIMATION_3D"
               data-promotion-id="0" 
               data-price="3999"
               data-price-text="$3999.00"
               data-title="3D Video" data-package-id="4219">Order Now</a>
         </div>
         <div class="fright">
            <div class="liovechats-bx">
               <a href="javascript:;" title="" onclick="setButtonURL();">
                  <span>Click here to</span>
                  <h5 class="montfont" onclick="setButtonURL();">Live Chat</h5>
               </a>
            </div>
         </div>
      </div>
   </div>
</div>                              </div>
                                                                                                </div>
               </div>
               <div class="row packagesall">
                  <div class="col-md-12 portfolio-mmbx">
                     <div class="leftcont-ban">
                        {/* <!-- <a class="montfont" href="javascript:;" title="">View All Packages <i class="fa fa-caret-down" aria-hidden="true"></i></a> --> */}
                        <a class="montfont" href="packages.html" title="">View All Packages
                        <i class="sprite btnarow"></i>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="video-development">
         <div class="container">
            <div class="row">
               <div class="vd-devp-fr">
                  <h2 class="montfont">Our Recipe to Create an Exceptional Animation</h2>
                  <p>We carefully monitor each step to produce an outstanding animated video.</p>
               </div>
            </div>
            <div class="row padd-tp responsive-slider-1">
               <div class="col-md-3">
                  <div class="vd-devp">
                     <div>
                        <span class="vd-numbering">01</span>
                        <img src="images/vd-devp-1.png"/>
                        <h3 class="montfont">Script Writing</h3>
                        <p>Only a strong script can create an impactful animation. We have professional script writers that carefully draft every action frame for an influential video.</p>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="vd-devp vd-bef">
                     <div>
                        <span class="vd-numbering">02</span>
                        <img src="images/vd-devp-2.png"/>
                        <h3 class="montfont">Story Board</h3>
                        <p>After the script is finalized the storyboard is created to depict the story. Our designers’ well-developed storyboards sculpt the script for initial feedback.</p>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="vd-devp vd-bef">
                     <div>
                        <span class="vd-numbering">03</span>
                        <img src="images/vd-devp-3.png"/>
                        <h3 class="montfont">Voice Over</h3>
                        <p>Once the storyboard is concluded, the voice artists are approached for voice over recording. We only contact the professionals so that the impact is not hindered.</p>
                     </div>
                  </div>
               </div>
               <div class="col-md-3">
                  <div class="vd-devp vd-bef">
                     <div>
                        <span class="vd-numbering">04</span>
                        <img src="images/vd-devp-4.png"/>
                        <h3 class="montfont">Animation</h3>
                        <p>Our highly skilled animators efficiently animate the videos and produce an end product that is innovative and aesthetically brilliant.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="maincta" data-img="url(images/ctabg.jpg) ">
         <div class="container">
            <div class="row">
               <div class="col-lg-5 col-md-6 col-sm-7 padright0">
                  <h3 class="montfont">Together we create a <strong>Unique Digital Entity!</strong> </h3>
                  <p class="montfont">Contact our experts NOW to begin crafting your project.</p>
               </div>
               <div class="col-lg-7 col-md-6 col-sm-5 padright0">
                  <div class="btn-radius-top">
                     <a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Packages"),
                                  (id = "pricing")
                                )
                              } title="">Select Package</a>
                     <a onClick={()=>setActive(true)} class="popup-open" href="javascript:;" title=""><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Request Custom Quote</a>
                  </div>
                  <div class="callsdivs montfont"><i class="sprite iconphone"></i>
                     <a href="tel:+1 (866) 726 3004" title="">Local Number: +1 (866) 726 3004</a>
                  </div>
               </div>
            </div>
         </div>
      </section>    

      
    </>
  )
}

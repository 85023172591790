import React from 'react'
import Mainlogo_banner from '../Sections/Mainlogo_banner'
import Site_about from '../Sections/Site_about'
import Range_section from '../Sections/Range_section'
import Portfolio from '../Sections/Portfolio' 
import Show_case from '../Sections/Show_case'
import Pricing_cards from '../Sections/Pricing_cards'

export const Main_logo = () => {
    return (
      <>
      <Mainlogo_banner/>
      <Site_about/>
      <Range_section/>
      <Show_case/>
      <Pricing_cards/>
      <Portfolio/>
      </>
    )
}
import React, { useState } from "react";
import Modal from "./Modal2";
import { NavLink, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";


export default function Branding() {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  
   const scrollTo = (ref, id) => {
     navigate(ref);
     setTimeout(() => {
       scroller.scrollTo(id, {
         spy: true,
         smooth: true,
         offset: 50,
         duration: 500,
       });
     }, 100);
   };
 

  return (
    <>
      <div className="branding">
        <section class="bannermm">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="leftcont-ban">
                  <h1 class="montfont">
                    <span>Uplift Your</span>
                    <br />
                    <strong> Brand Identity</strong> And Make Your Mark
                  </h1>
                  <p>With Exceptional Designs by Webions</p>
                  <a
                    class="montfont poundbtn brandbtnsstart popup-open"
                    onClick={() => setActive(true)}
                    title=""
                  >
                    Start Your Branding Project from $249
                    <i class="sprite btnarow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal active={active} setActive={setActive} />
      </div>

      <section class="brdcrme">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="content-incred">
                <ul>
                  {" "}
                  <li>
                  <NavLink to="/" >Home</NavLink>
                  </li>
                  <li>
                   
               
                     <NavLink to="/Branding_design">Branding Design</NavLink>
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="incred-expe" id="packagesdropsbrand">
        <div class="container fullslider">
          <div class="row">
            <div class="slides-mm">
              <div class="col-lg-7 col-md-7">
                <div class="content-incred">
                  <h5 class="montfont fbold f24">
                    Customized Branding Services
                  </h5>
                  <h3 class="montfont f30">
                    Branding can serve as the most responsive promotional tool!
                  </h3>
                  <p>
                    Webions creates innovative designs that make your brand
                    stand apart from the rest of the respective industry. Our
                    distinctive designs will attract your clients towards your
                    brand providing you a competitive edge. Our team of experts
                    creatively crafts every project by giving intensive
                    attention to every detail that results in an unforgettable
                    impression on your clients’ minds.
                  </p>
                  <p>
                    {" "}
                    Create an astonishing presence in the market by branding
                    done at Webions. We have all the capabilities to understand
                    your comprehensive requirements and enhance your brand
                    visibility.
                  </p>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 md-hidden">
                <img src="images/branding-1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="branding-portfolio" id="branddropsportfolio">
        <div class="container">
          <div class="row">
            <div class="c-tabs">
              <div class="tabs-box tabs-box-cer">
                <div class="col-md-3 col-sm-3 col-xs-12 nopadd-l">
                  <h3 class="montfont">Best Branding Solutions</h3>
                  <p>
                    We are a team of dedicated professionals and well-known for
                    providing top-class branding solutions to our clients.
                  </p>
                  <ul class="tabs-list">
                    <li tab="tab1" class="active">
                      <a href="javascript:;" title="">
                        {" "}
                        Stationery
                      </a>
                    </li>
                    <li tab="tab2">
                      <a href="javascript:;" title="">
                        Banners
                      </a>
                    </li>
                    <li tab="tab3">
                      <a href="javascript:;" title="">
                        Brochures
                      </a>
                    </li>
                    <li tab="tab4">
                      <a href="javascript:;" title="">
                        Packaging
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-9 col-sm-9 col-xs-12">
                  <div class="row">
                    <div id="tab1" class="tab-content active bg-1">
                      <div class="bg1-description">
                        <div class="col-md-7 col-sm-12 col-xs-12 nopadd-lr">
                          <img src="images/brand-portfolio1.png" />
                        </div>
                        <div class="col-md-5 col-sm-12 col-xs-12">
                          <div class="c-tabs-description">
                            <h3 class="montfont fbold">Stationery</h3>
                            <p>
                              Webions provides flawless designs with perfection
                              that will enhance your brand identity. Our
                              creativity will deliver the correct attitude of
                              your brand to your customers with innovatively
                              designed stationery products while keeping your
                              brand’s originality. The stationery designed by
                              Webions will put across a positive message about
                              your business with brand consistency and
                              professionalism.
                            </p>
                            <a class="montfont" href="stationery-design.html">
                              Explore
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="tab2" class="tab-content bg-2">
                      <div class="bg1-description">
                        <div class="col-md-7 col-sm-12 col-xs-12 nopadd-lr">
                          <img src="images/brand-portfolio2.png" />
                        </div>
                        <div class="col-md-5 col-sm-12 col-xs-12">
                          <div class="c-tabs-description">
                            <h3 class="montfont fbold">Banners</h3>
                            <p>
                              Webions aspires to design banners for your website
                              that will serve as a customer magnet. It will
                              enable your customers to perceive you as a
                              prominent entity. You do not need to battle for
                              customer attention, grab it immediately with
                              meticulously designed banners by our creative
                              designers and uplift your brand presence. Not only
                              websites, we are specialists in designing banners
                              for print, emails and social media.
                            </p>
                            <a class="montfont" href="banner-design.html">
                              Explore
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="tab3" class="tab-content bg-3">
                      <div class="bg1-description">
                        <div class="col-md-7 col-sm-12 col-xs-12 nopadd-lr">
                          <img src="images/brand-portfolio3.png" />
                        </div>
                        <div class="col-md-5 col-sm-12 col-xs-12">
                          <div class="c-tabs-description">
                            <h3 class="montfont fbold">Brochures</h3>
                            <p>
                              A well-developed brochure is an integral part of
                              any brand’s marketing campaign. It is the most
                              effective impact on the audiences as it holds the
                              comprehensive information of the brand and its
                              products and services. Brochures are the most
                              targeted approach for advertising. Webions
                              specialises in brochure designing and the creative
                              outlook of your business brochure will assist you
                              to advertise your brand to the masses.
                            </p>
                            <a class="montfont" href="broucher-design.html">
                              Explore
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="tab4" class="tab-content bg-4">
                      <div class="bg1-description">
                        <div class="col-md-7 col-sm-12 col-xs-12 nopadd-lr">
                          <img src="images/brand-portfolio4.png" />
                        </div>
                        <div class="col-md-5 col-sm-12 col-xs-12">
                          <div class="c-tabs-description">
                            <h3 class="montfont fbold">Packaging</h3>
                            <p>
                              At Webions we target brand strategies that
                              establish a strong connection between your brand
                              and your customers effectively. We ensure that all
                              these strategies are inculcated in the packaging
                              design we develop. We offer a wide range of
                              packaging designs which are creatively designed
                              and can be practically applied to enhance your
                              brand in the market.{" "}
                            </p>
                            <a class="montfont" href="packages.html">
                              Explore
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="homeport-mm" id="branding_portfolio">
        <div class="container">
          <div class="sechead">
            <h3 class="montfont">Take a Sneak Peek at our Portfolio</h3>
            <p>
              We take immense pride for the associations we created with some
              prestigious names through our outstanding designs.
            </p>
          </div>
        </div>

        <div class="portfoliomaindivs tabsmainport">
          <div class="topbartabs">
            <div id="All" class="tabs-portfolio innertabcontents  active">
              <div class="portfolio-mmbx">
                <div id="innerall2" class="innertabcontents active">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row responsive-slider">
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary01.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>Divine Fresh</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary01.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary02.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>Trinity Financial Group</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary02.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary03.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>Cutting Edge</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary03.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary04.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>Golden Fox</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary04.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary05.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>Karaken</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary05.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary06.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>Manish Khatri</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary06.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary07.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>One eighty</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary07.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary08.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>MFR</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary08.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary09.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>Pedikidz</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary09.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="box-ports">
                            <img
                              data-src="images/portfolio/branding/Stationary10.jpg"
                              alt=""
                            />
                            <div class="hovereffects-det">
                              <h3>MedStar</h3>
                              <div class="btnstyles-nn">
                                <a
                                  data-fancybox="branding"
                                  href="images/portfolio/branding/Stationary10.jpg"
                                  title=""
                                >
                                  Detail View{" "}
                                  <i
                                    class="fa fa-search-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container leftcont-ban">
                  <a class="montfont" href="portfolio.html" title="">
                    View All Projects <i class="sprite btnarow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="det-packages" id="packdropsbrand">
        <div class="container">
          <div class="sechead">
            <h3 class="montfont">
              Affordable Packages Crafted As Per The Needs Of Your Business{" "}
            </h3>
            <p>
              Webions offers the most cost-friendly packages especially designed
              in accordance with your requirements.
            </p>
          </div>
          <div class="packagesbxs">
            <div class="row justify-content-center">
              <div id="pack1" class="tabscontent responsive-slider">
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Branding Startup</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $499 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$1663</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>
                            <span>Logo Design</span>{" "}
                          </li>
                          <li>6 Unique Logo Concepts</li>
                          <li>FREE Icon </li>
                          <li>FREE Grayscale Copy </li>
                          <li>Unlimited Revisions </li>
                          <li>100% Ownership Right </li>
                          <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats </li>
                          <li>
                            <span>Print Media </span>{" "}
                          </li>
                          <li>
                            Stationery Design (Business Card, Letterhead,
                            Envelope)
                          </li>
                          <li>
                            <span>Website Design</span>
                          </li>
                          <li>5 Pages Custom Business Website</li>
                          <li>Complete Deployment</li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            href="javascript:;"
                            data-sku="BRANDING_BRANDING_STARTUP"
                            data-promotion-id="0"
                            data-price="499"
                            data-price-text="$499.00"
                            data-title="Branding Startup"
                            data-package-id="2808"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                              onclick="setButtonURL();"
                            >
                              <span>Click here to</span>
                              <h5 class="montfont" onclick="setButtonURL();">
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Branding Plus</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $999 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$3333</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>
                            <span>Logo Design</span>
                          </li>
                          <li>12 Unique Logo Concepts </li>
                          <li>FREE Icon </li>
                          <li>FREE Grayscale Copy </li>
                          <li>Unlimited Revisions </li>
                          <li>100% Ownership Right </li>
                          <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats</li>
                          <li>
                            <span>Print Material</span>
                          </li>
                          <li>
                            Stationery Design(Business Card,Letterhead,Envelope)
                          </li>
                          <li>Flyer Design / Label Design</li>
                          <li>
                            <span>Website Design</span>
                          </li>
                          <li>Custom 5 Pages Professional Website</li>
                          <li>Content Management System (WordPress)</li>
                          <li>Responsive Design (Mobile Compatible)</li>
                          <li>Complete Deployment</li>
                          <li>Social Media Design</li>
                          <li>Facebook Fanpage Splash Page + Cover Photo</li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            href="javascript:;"
                            data-sku="BRANDING_BRANDING_PLUS"
                            data-promotion-id="0"
                            data-price="999"
                            data-price-text="$999.00"
                            data-title="Branding Plus"
                            data-package-id="2809"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                              onclick="setButtonURL();"
                            >
                              <span>Click here to</span>
                              <h5 class="montfont" onclick="setButtonURL();">
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Branding Classic</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $1499 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$4993</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>
                            <span>Logo Design</span>
                          </li>
                          <li>12 Unique Logo Concepts</li>
                          <li>FREE Icon </li>
                          <li>FREE Grayscale Copy </li>
                          <li>Unlimited Revisions </li>
                          <li>100% Ownership Right </li>
                          <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats</li>
                          <li>Print Material</li>
                          <li>
                            Stationery Design(Business Card,Letterhead,Envelope)
                          </li>
                          <li>
                            <span>Any Product of your Choice:</span>
                          </li>
                          <li>
                            Bi-fold/Tri-fold Brochure / Menu Card / Corporate
                            Profile)
                          </li>
                          <li>Website Design</li>
                          <li>10+ Page Website</li>
                          <li>Content Management System (WordPress/Joomla)</li>
                          <li>Complete Deployment</li>
                          <li>Search Engine Submission</li>
                          <li>Google, Bing, Yahoo and More</li>
                          <li>Social Media Design</li>
                          <li>Facebook Fanpage Splash Page + Cover Photo</li>
                          <li>Twitter Background Design</li>
                          <li>Google+ Profile Banner</li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            href="javascript:;"
                            data-sku="BRANDING_BRANDING_CLASSIC"
                            data-promotion-id="0"
                            data-price="1499"
                            data-price-text="$1499.00"
                            data-title="Branding Classic"
                            data-package-id="4217"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                              onclick="setButtonURL();"
                            >
                              <span>Click here to</span>
                              <h5 class="montfont" onclick="setButtonURL();">
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class="col-md-3">
                  <div class="boxpackages" data-package-box>
                    <div class="packheads">
                      <h3 class="montfont">Branding Ultimate</h3>
                      <p style={{ display: "none" }}>
                        Ideal for beginners and start-ups
                      </p>
                    </div>
                    <div class="packdetails">
                      <div class="packtitles">
                        <div class="fleft">
                          <h3 class="opensansfont">
                            $1999 <sub class="">60% off</sub>
                          </h3>
                        </div>
                        <div class="fright">
                          <h4>
                            WAS
                            <b>
                              <span class="cutprice">$6663</span>
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div class="scroll listpacks" data-package-scroll>
                        <ul>
                          <li>
                            <span>Logo Design</span>
                          </li>
                          <li>Infinite Unique Logo Concepts</li>
                          <li>FREE Icon </li>
                          <li>FREE Grayscale Copy </li>
                          <li>Unlimited Revisions </li>
                          <li>100% Ownership Right </li>
                          <li>AI, PSD, EPS, GIF, BMP, JPEG, PNG Formats</li>
                          <li>
                            <span>Print Material</span>
                          </li>
                          <li>
                            Stationery Design(Business Card,Letterhead,Envelope)
                          </li>
                          <li>
                            500 Business Cards, Letterhead, Envelop Prints
                          </li>
                          <li>
                            <span>Any Product of your Choice:</span>
                          </li>
                          <li>
                            Bi-fold/Tri-fold Brochure / Menu Card / Corporate
                            Profile
                          </li>
                          <li>Website Design</li>
                          <li>10+ Page Website</li>
                          <li>Content Management System (WordPress/Joomla)</li>
                          <li>Responsive Design (Mobile Compatible)</li>
                          <li>Complete Deployment</li>
                          <li>Social Media Design</li>
                          <li>Facebook Fanpage Splash Page + Cover Photo</li>
                          <li>Twitter Background Design</li>
                          <li>Google+ Profile Banner</li>
                          <li>
                            Complete Storyboard, VoiceOver &amp; Animation
                          </li>
                        </ul>{" "}
                      </div>
                      <div class="botarea-pack">
                        <div class="fleft">
                          <a
                            class="montfont btn-orders order-package"
                            href="javascript:;"
                            data-sku="BRANDING_BRANDING_ULTIMATE"
                            data-promotion-id="0"
                            data-price="1999"
                            data-price-text="$1999.00"
                            data-title="Branding Ultimate"
                            data-package-id="4218"
                          >
                            Order Now
                          </a>
                        </div>
                        <div class="fright">
                          <div class="liovechats-bx">
                            <a
                              href="javascript:;"
                              title=""
                              onclick="setButtonURL();"
                            >
                              <span>Click here to</span>
                              <h5 class="montfont" onclick="setButtonURL();">
                                Live Chat
                              </h5>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div class="row packagesall">
              <div class="col-md-12 portfolio-mmbx">
                <div class="leftcont-ban">
                  {/* <!-- <a class="montfont" href="javascript:;" title="">View All Packages <i class="fa fa-caret-down" aria-hidden="true"></i></a> --> */}
                  <a class="montfont" href="packages.html" title="">
                    View All Packages
                    <i class="sprite btnarow"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="how-works">
        <div class="container">
          <div class="sechead">
            <h3 class="montfont">Our Recipe to Create an Exceptional Design</h3>
            <p>
              We observe a thorough and elaborated process to present unique and
              exclusive products.
            </p>
          </div>

          <div class="howworks-mm">
            <div class="row slider-2">
              <div class="col-md-3">
                <div class="designbrief">
                  <div class="processicons">
                    <i class="sprite icon1"></i>
                  </div>
                  <h3>Design Brief</h3>
                  <p>
                    The client submits a precise definition of what they require
                    and how they want to see their design.
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="designbrief">
                  <div class="processicons">
                    <i class="sprite icon2"></i>
                  </div>
                  <h3>Initial Samples</h3>
                  <p>
                    Our design team's creative instincts take charge enabling
                    them to provide with initial samples as per your selected
                    package.
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="designbrief">
                  <div class="processicons">
                    <i class="sprite icon3"></i>
                  </div>
                  <h3>Tweaking</h3>
                  <p>
                    Variations and modifications done based on your preferences
                    to the selected design.
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="designbrief">
                  <div class="processicons">
                    <i class="sprite icon4"></i>
                  </div>
                  <h3>Final Delivery</h3>
                  <p>
                    Your brand new design completely done to your specifications
                    is delivered to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="maincta" data-img="url(images/ctabg.jpg) ">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-7 padright0">
              <h3 class="montfont">
                Together we create a <strong>Unique Digital Entity!</strong>{" "}
              </h3>
              <p class="montfont">
                Contact our experts NOW to begin crafting your project.
              </p>
            </div>
            <div class="col-lg-7 col-md-6 col-sm-5 padright0">
              <div class="btn-radius-top">
                <a
                  onClick={(ref, id) =>
                    scrollTo((ref = "/Packages"), (id = "pricing"))
                  }
         
                  title=""
                >
                  Select Package
                </a>
                <a
                  onClick={() => setActive(true)}
                  class="popup-open"
            
                  title=""
                >
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
                  Request Custom Quote
                </a>
              </div>
              <div class="callsdivs montfont">
                <i class="sprite iconphone"></i>
                <a href="tel:+1 (866) 726 3004" title="">
                  Local Number: +1 (866) 726 3004
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


import { Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home';
import { About } from './Pages/About';
import { Portfoli } from './Pages/Porfoli'
import { Contact_main } from './Pages/Contact_main';
import { Packages } from './Pages/Packages';
import { Main_logo } from './Pages/Main_logo';
import { Review } from './Pages/Review'
import { Website } from './Pages/Website'
import { Nft_design } from './Pages/Nft_design'
import { Animation_design } from './Pages/Animation_design'
import { Branding_design } from './Pages/Branding_design';
import Test2 from './Sections/Test2';

import './Styles/App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import 'cleave.js/dist/addons/cleave-phone.i18n.js'




function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/About" element={<About/>}/>
      <Route path="/Portfoli" element={<Portfoli/>}/>
      <Route path="/Contact_main" element={<Contact_main/>}/>
      <Route path="/Packages" element={<Packages/>}/>
      <Route path="/Main_logo" element={<Main_logo/>}/>
      <Route path="/test" element={<Test2/>}/>
      <Route path="/Review" element={<Review/>}/>
      <Route path="/Website" element={<Website/>}/>
      <Route path="/Nft_design" element={<Nft_design/>}/>
      <Route path="/Animation_design" element={<Animation_design/>}/>
      <Route path="/Branding_design" element={<Branding_design/>}/>



      <Route path="/test" element={<Test2/>}/>

      

      
    </Routes>
    </>
    )
}

export default App;

import React from 'react'

export default function Nft_cards() {
  return (
    <>
     <section class="incred-expe" id="section08">
      <div class="container fullslider">
         <div class="row">
            <div class="slides-mm">
               <div class="col-lg-7 col-md-7">
                  <div class="content-incred">
                     <h5 class="montfont fbold f24">We are experts at designing great NFTs</h5>
                     <h3 class="montfont f30">NFTs we have created have given great profits to our prestigious customers</h3>
                     <p>Our NFT design and rendering capabilities include a vast array of 2D and 3D rendering, game environment designing, character and mascot illustrations, and animation services. We ensure that every design our graphic designers create innovative and unique so that your NFT stands out in the marketplace. </p>
                     <p>We at Webions are dedicated towards creating phenomenal designs that can take over the NFT world and make you big bucks. Through our attention to detail and an emphasized focus on quality, we bring your project to life with precise dimensions, proportions, and textures.</p>
                  </div>
               </div>
               <div class="col-lg-5 col-md-5 md-hidden">
                  <img src="images/nft-design/nft-01.png" alt=""/>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="animation-service">
      <div class="container">
         <div class="row responsive-slider-1">
            <div class="col-md-6">
               <div class="srvc-box">
                  <div class="srvc-lft">
                     <a href="images/nft-design/2d.png" title="">
                        {/* <!-- <img class="gif-img" src="images/gif/normalgif1.gif" alt=""> --/> */}
                        <img class="gif-img" src="images/nft-design/2d.png" alt=""/>
                     </a>
                  </div>
                  <div class="srvc-rht">
                     <h4 class="montfont">2D Art</h4>
                     <p>Our team of expert designers are capable of producing phenomenal 2D designs for your NFTs. All you have to do is provide them with a brief and let them work their magic on it.</p>
                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="srvc-box">
                  <div class="srvc-lft">
                     <img src="images/nft-design/3d.png"/>
                  </div>
                  <div class="srvc-rht">
                     <h4 class="montfont">3D Art</h4>
                     <p>3D art requires a lot of effort and detailing that we at Webions are more than willing to put in. Our qualified designers will create innovative 3D designs for you to own a 3D NFT.</p>

                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="srvc-box">
                  <div class="srvc-lft">
                     <img src="images/nft-design/animation.png"/>
                  </div>
                  <div class="srvc-rht">
                     <h4 class="montfont">Animations</h4>
                     <p>An animated NFT can do wonders in the marketplace when it is aesthetically pleasing. Our animation team ensures that they deliver nothing but the best so that you turn into a happy customer.</p>

                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="srvc-box">
                  <div class="srvc-lft">
                     <img src="images/nft-design/mascots.png"/>
                  </div>
                  <div class="srvc-rht">
                     <h4 class="montfont">Characters & Mascots</h4>
                     <p>Get your crypto character or mascot designed by Webions and turn it into NFT artwork for sale. We leave no stone unturned to deliver our best so that you get to enjoy royalties.</p>

                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="srvc-box">
                  <div class="srvc-lft">
                     <img src="images/nft-design/game.png"/>
                  </div>
                  <div class="srvc-rht">
                     <h4 class="montfont">Game Development</h4>
                     <p>With a fantastic team on our side, thousands of hours, and hundreds of clients, we ensure that your game idea is turned into a playable NFT game and generates lasting value for players</p>

                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="srvc-box">
                  <div class="srvc-lft">
                     <img src="images/nft-design/modeling.png"/>
                  </div>
                  <div class="srvc-rht">
                     <h4 class="montfont">Environment Modeling</h4>
                     <p>Do you think you have an idea to become a successful NFT seller? Our qualified professionals create NFT environments that are close to reality in order to offer a real life experience.</p>

                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>   
   <section class="homeport-mm" id="animdropsPort">
      <div class="container">
         <div class="sechead">
            <h3 class="montfont">Take a Sneak Peek at our Portfolio</h3>
            <p>We take immense pride for the associations we created with some prestigious names through our outstanding designs.</p>
         </div>
      </div>
      <div class="portfoliomaindivs tabsmainport">
         <div class="topbartabs">
            <div id="All" class="tabs-portfolio innertabcontents active">
               <div class="portfolio-mmbx">
                  <div id="innerall2" class="innertabcontents active">
                     <div class="row">
                        <div class="col-md-12" data-uw-styling-context="true">
                           <div class="row responsive-slider" data-uw-styling-context="true">
                              <div class="col-md-3" data-uw-styling-context="true">
                                 <div class="box-ports" data-uw-styling-context="true">
                                    <img data-src="images/nft-design/portfolio/01.jpg" alt="" data-uw-styling-context="true" src="images/nft-design/portfolio/01.jpg" class="imgadded"/>
                                 </div>
                              </div>

                              <div class="col-md-3" data-uw-styling-context="true">
                                 <div class="box-ports" data-uw-styling-context="true">
                                    <img data-src="images/nft-design/portfolio/05.jpg" alt="" data-uw-styling-context="true" src="images/nft-design/portfolio/05.jpg" class="imgadded"/>
                                 </div>
                              </div>
                              <div class="col-md-3" data-uw-styling-context="true">
                                 <div class="box-ports" data-uw-styling-context="true">
                                    <img data-src="images/nft-design/portfolio/03.jpg" alt="" data-uw-styling-context="true" src="images/nft-design/portfolio/03.jpg" class="imgadded"/>
                                 </div>
                              </div>
                              <div class="col-md-3" data-uw-styling-context="true">
                                 <div class="box-ports" data-uw-styling-context="true">
                                    <img data-src="images/nft-design/portfolio/06.jpg" alt="" data-uw-styling-context="true" src="images/nft-design/portfolio/06.jpg" class="imgadded"/>
                                 </div>
                              </div>
                              <div class="col-md-3" data-uw-styling-context="true">
                                 <div class="box-ports" data-uw-styling-context="true">
                                    <img data-src="images/nft-design/portfolio/02.jpg" alt="" data-uw-styling-context="true" src="images/nft-design/portfolio/02.jpg" class="imgadded"/>
                                 </div>
                              </div>
                              <div class="col-md-3" data-uw-styling-context="true">
                                 <div class="box-ports" data-uw-styling-context="true">
                                    <img data-src="images/nft-design/portfolio/08.jpg" alt="" data-uw-styling-context="true" src="images/nft-design/portfolio/08.jpg" class="imgadded"/>
                                 </div>
                              </div>
                              <div class="col-md-3" data-uw-styling-context="true">
                                 <div class="box-ports" data-uw-styling-context="true">
                                    <img data-src="images/nft-design/portfolio/07.jpg" alt="" data-uw-styling-context="true" src="images/nft-design/portfolio/07.jpg" class="imgadded"/>
                                 </div>
                              </div>
                              <div class="col-md-3" data-uw-styling-context="true">
                                 <div class="box-ports" data-uw-styling-context="true">
                                    <img data-src="images/nft-design/portfolio/04.jpg" alt="" data-uw-styling-context="true" src="images/nft-design/portfolio/04.jpg" class="imgadded"/>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>

   <section class="video-development nft-design">
      <div class="container">
         <div class="row">
            <div class="vd-devp-fr">
               <h2 class="montfont">Our Recipe to Create a Fantastic NFT</h2>
               <p>We carefully monitor each step to produce amazing designs for your NFTs.</p>
            </div>
         </div>
         <div class="row padd-tp responsive-slider-1">
            <div class="col-md-3">
               <div class="vd-devp r-heigt">
                  <div>
                     <span class="vd-numbering">01</span>
                     <img src="images/nft-design/nft-vrctor1.png"/>
                     <h3 class="montfont">Brief</h3>
                     <p>We begin the process by identifying and discussing your requirements. Expert in NFT projects, our technical team studies your project and offers best advice so that you can make your project a success.</p>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="vd-devp vd-bef">
                  <div>
                     <span class="vd-numbering">02</span>
                     <img src="images/nft-design/nft-vrctor2.png"/>
                     <h3 class="montfont">Illustration & Rendering</h3>
                     <p>Our experts then begin designing an outline as per your requirements communicated in the first phase. During a series of review rounds, the selected design is then optimized.</p>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="vd-devp vd-bef">
                  <div>
                     <span class="vd-numbering">03</span>
                     <img src="images/nft-design/nft-vrctor3.png"/>
                     <h3 class="montfont">Project QA</h3>
                     <p>All your requirements are kept in mind and a thorough review is carried out by our expert quality assurance officers so that you are 100% satisfied with your project</p>
                  </div>
               </div>
            </div>
            <div class="col-md-3">
               <div class="vd-devp vd-bef">
                  <div>
                     <span class="vd-numbering">04</span>
                     <img src="images/nft-design/nft-vrctor4.png"/>
                     <h3 class="montfont">Delivery</h3>
                     <p>Your project is then finalized for delivery and all the necessary files are delivered to you so that you can enjoy your royalties at the earliest.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   
    </>
  )
}
import React, { useState } from 'react'
import BasicModal from './Modal';
import axios from 'axios';

export default function Contact_form() {
    const [formData, setFormData] = useState({
        email: "",
        name: "",
        number: "",
        message: "",
        subject:""
      });
    
      const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [message,setMessage] = useState({error:true,mes:""})
   
      
    
      const onChange = (event) =>{
        const {name,value} = event.target
        setFormData({...formData,[name] : value})
      }
    
      const handleSubmit = async (event) =>{
        event.preventDefault()
        const data = {
          name: formData.name,
          email: formData.email,
          number: formData.number,
          message: formData.message,
          subject:formData.subject
        };
      
        try {
            
          if (formData.name && formData.email && formData.number && formData.message && formData.subject) {
            const response = await axios.post('https://webions.co.uk/Backend/simpleemail/', data);
            setMessage({...message,mes:"Contact Form Submitted Successfully",error:false})
            handleOpen()
          
        
       } else{
        setMessage({...message,mes:"Fill All The values in the Form",error:true})
        handleOpen()
      }
      
        } catch (error) {
          setMessage({...message,mes:"Something Went Wrong Please Try Again",error:true})
          console.error(error);
          handleOpen()
        };
      

  };

      
  return (
    <>
    <BasicModal  open={open} handleOpen={handleOpen} setOpen={setOpen} setMessage={setMessage} message={message}/>
        <section className="contact-main py-5">
            
        <div className="container">
            <div className="sechead">
                <h3 className="montfont">Get in touch with us!</h3>
                <p>Team Webions is here to guide you.
                    <br/> Tell us what you think or send us your query and we will get back to you at our soonest.
                </p>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="contact-form-main">
                        <div id="contact-form" className="gray-bg" data-form-type="footer_form">
                            <form className="jform validate leadForm">
                                <h4>Fill the required information:</h4>
                                <input type="hidden" id="formType" name="formType"/>
                                <input type="hidden" id="referer" name="referer"/>
                                <input type="hidden" id="package_sku" value=""/>
                                <input type="hidden" id="price" value="0.00"/>
                                <input type="hidden" id="title" value=""/>
                                <input type="hidden" id="package-id" value=""/>

                                <div className="field mm-field name">
                                    <i className="user"></i>
                                    <input type="text" name="name" value={formData.name} onChange={onChange} data-validation="required" placeholder='Your Name'/>
                                </div>
                                <div className="field mm-field email">
                                    <i className="email"></i>
                                    <input type="text" name="email" value={formData.email} onChange={onChange} data-validation="required" placeholder='Email Address'/>
                                </div>
                                <div className="field mm-field phone">
                                    <i className="phone"></i>
                                    <input type="text" name="number" id="phone" value={formData.number} onChange={onChange} data-validation="required" placeholder='Phone Number'/>
                                </div>
                                <div className="field mm-field textarea">
                                    <i className="subject"></i>
                                    <input type="text" name="subject" value={formData.subject} onChange={onChange}  placeholder='Subject'/>
                                </div>
                                <div className="field mm-field full textarea">
                                    <textarea name="message" id="message"   value={formData.message} onChange={onChange} rows="12" placeholder='Message'></textarea>
                                </div>
                                <div className="submit">
                                        <div id="formResult"></div>
                                       <div className="clearfix"></div>
                                    {/* <!-- <input type="submit" value="Submit Now" /> --> */}
                                    <button type="submit" id="signupBtn" name="signupForm" onClick={handleSubmit} >Submit Now</button>
                                </div>
                            </form>
                        </div>


                        <div className="red-bg">
                            <h3 className="montfont">Contact Us</h3>

                            {/* <!-- <h4>Address</h4>
                            <p>5042 Wilshire Blvd #43900 Los Angeles, CA 90036</p> --> */}
                            <div className="row">
                                <div className="col-480-100 col-xs-6 col-sm-4 col-md-12">
                                    <h4>Email</h4>
                                    <p>info@thewebions.com</p>
                                </div>
                                <div className="col-480-100 col-xs-6 col-sm-4 col-md-12">
                                    <h4>Local Number</h4>
                                    <p>
                                        <a href="tel:+1 (866) 726 3004">+44 20 3289 8634 </a>
                                    </p>
                                </div>

                                <div className="col-480-100 col-xs-6 col-sm-12 col-md-12">
                                    <div className="social-icons">
                                        <h4>Social Media</h4>
                                        <div>
                                            <a href="https://www.facebook.com/TheWebions/" target="_blank">
                                                <i className="fa-brands fa-facebook-f"></i>
                                            </a>
                                            <a href="https://www.facebook.com/TheWebions/" target="_blank">
                                                <i className="fa-brands fa-instagram ml-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="col-480-100 col-xs-6 col-sm-12 col-md-12">
                                    <div className="social-icons">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i><p>Lytchett House, 13 Freeland Park, Wareham Road, Poole, Dorset, BH16 6FA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    </section>
    </>
  )
}
import React from 'react'
import { useNavigate } from 'react-router-dom';

import { scroller } from "react-scroll";
export default function Footer_site() {
	
	const navigate = useNavigate();
  
  const scrollTo = (ref, id) => {
	
    navigate(ref);
    setTimeout(() => {
      scroller.scrollTo(id, {
        spy: true,
        smooth: true,
        offset: 50,
        duration: 500,
      });
    }, 100);
  };
  return (
    <>
          <footer>
	<div className="container">
		<div className="row listpge">
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme">
					<h3 >Logo Services</h3>
					<ul>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Main_logo"),
                                  (id = "main_logo")
                                )
                              }>Logo Design</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Main_logo"),
                                  (id = "pricing")
                                )
                              }>Logo Packages</a></li>
						{/* <!-- <li><a href="/javascript:;">How it Works</a></li> --> */}
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Main_logo"),
                                  (id = "porfolio")
                                )
                              }>Logo Portfolio</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Review"),
                                  (id = "top")
                                )
                              }>Reviews</a></li>
					</ul>
				</div>
			</div>
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme">
					<h3>Website Services</h3>
					<ul>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Website"),
                                  (id = "top")
                                )
                              }>Website Design</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Website"),
                                  (id = "web_pricing")
                                )
                              }>Website Packages</a></li>
						{/* <!-- <li><a href="/javascript:;">How it Works</a></li> --> */}
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Website"),
                                  (id = "web_portfolio")
                                )
                              }>Website Portfolio</a></li>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Review"),
                                  (id = "top")
                                )
                              }>Reviews</a></li>
					</ul>
				</div>
			</div>
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme-ctact">
					<h3>Other Services</h3>
					<ul>
						<li><a href="#">Animated Videos</a></li>
						<li><a href="#">Brochure Design</a></li>
						<li><a href="#">Stationery Design</a></li>
						<li><a href="#">Banner Design</a></li>
						<li><a href="#">Packaging Design</a></li>
					</ul>
				</div>
			</div>
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme">
					<h3>Quick Links</h3>
					<ul>
						<li><a onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/About"),
                                  (id = "top")
                                )
                              }>About Us</a></li>
						<li><a href="#">FAQs</a></li>
						<li><a href="#">Our Blog</a></li>
						<li><a href="#">Privacy & Security</a></li>
						<li><a href="#">Terms & Conditions</a></li>
						{/* <!-- <li><a href="/sitemap.php">Sitemap</a></li> --> */}
					</ul>
				</div>
			</div>
			<div className="col-md-5ths col-sm-5ths">
				<div className="ulsme-ctct">
					<h3>Contact Us</h3>
					<ul>
						<li className="phone-list"><a href="tel:+442032898634">+44 20 3289 8634</a></li>
						<li className="email-list"><a href="mailto:info@thewebions.com">info@thewebions.com</a></li>
						{/* <!-- <li className="chat-list"><a href="javascript_%3b.html" className="chat">Live Chat</a></li> --> */}
						<li className="address-list"><a>Lytchett House, 13 Freeland Park, Wareham Road, Poole, Dorset, BH16 6FA</a></li>
					</ul>
				</div>
			</div>
		</div>
		<div className="row brdr-top ">
			<div className="col-md-6 col-sm-6 col-xs-6">
				<div className="footer-logo padd-btd">
					<img className="img-responsive" src="images/webions_logo_trans.png"/>
				</div>
			</div>
			<div className="col-md-6 col-sm-6 col-xs-64">
				<div className="social-icons padd-btd">
					<h4>Follow Us</h4>
					<a href="https://www.facebook.com/TheWebions/" target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
					<a href="https://www.instagram.com/thewebions/?hl=en" target="_blank"><i className="fa-brands fa-instagram"></i></a>
					<a href="https://pk.linkedin.com/company/webions" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a>
				</div>
			</div>
		</div>
	</div>
	<div className="footer-bottom">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<h4>© 2023 Webions, All Rights Reserved.</h4>
					<p>Disclaimer: The logo, name and graphics of Webions and its products & services are the trademarks of Webions. All other company names, brand names, trademarks and logos mentioned on this website are the property of their respective owners and do not constitute or imply endorsement, sponsorship or recommendation thereof by Webions and do not constitute or imply endorsement, sponsorship or recommendation of Webions by the respective trademark owner.</p>
				</div>
			</div>
		</div>
	</div>
</footer>
    </> 
  )
}
import React from 'react'

export default function range_section() {
  return (
    <>
      <section class="what-offer" data-img="url(images/whatoffer-bg.jpg)">
         <div class="container">
            <h3 class="montfont">Comprehensive Range Of Services We Offer</h3>
            <p>Build Your Business with Our 360&#176; Designing & Branding Solutions</p>
            <ul class="slider-2" >
               <li>
                  <div onclick="location.href='logo-design.html';">
                     <h3 class="montfont redcol">Logo
                        <br/>Design
                     </h3>
                     <p>Get noticed by your audience with high-Quality, professional and affordable Logo Designs.</p>
                     <a href="logo-design.html" title="">Explore
                     <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                     </a>
                  </div>
                  <i class="sprite icon1"></i>
               </li>
               <li>
                  <i class="sprite icon2"></i>
                  <div onclick="location.href='web-design.html';">
                     <h3 class="montfont redcol">Website
                        <br/> Design
                     </h3>
                     <p>Webions offers a variety of web design services from mock-ups, to full website redesigns.</p>
                     <a href="web-design.html" title="">Explore
                     <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                     </a>
                  </div>
               </li>
               <li>
                  <div onclick="location.href='branding-design.html';">
                     <h3 class="montfont redcol">Branding
                        <br/> Design
                     </h3>
                     <p>In a strategic and holistic approach to branding, Webions is an expert, which thrives on change.</p>
                     <a href="branding-design.html" title="">Explore
                     <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                     </a>
                  </div>
                  <i class="sprite icon3"></i>
               </li>
               <li>
                  <i class="sprite icon4"></i>
                  <div onclick="location.href='animation-design.html';">
                     <h3 class="montfont redcol">Animated
                        <br/> Videos
                     </h3>
                     <p>From concept to completion, Webions provides a full suite of illustrative animation to glow your business.
                     </p>
                     <a href="animation-design.html" title="">Explore
                     <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                     </a>
                  </div>
               </li>
            </ul>
         </div>
      </section>
    </>
  )
}
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Link as a, animateScroll as scroll } from "react-scroll";

import { scroller } from "react-scroll";
import Modal from "./Modal2";
import Test2 from "./Test2";

export default function Navbar() {
  const navigate = useNavigate();
  
  const scrollTo = (ref, id) => {
    navigate(ref);
    setTimeout(() => {
      scroller.scrollTo(id, {
        spy: true,
        smooth: true,
        offset: 50,
        duration: 500,
      });
    }, 100);
  };

  const [hover, setHover] = useState(false);
  const [name, setName] = useState("");

  const handleMouseOver = (e) => {
    setHover(true);
    setName(e.target.getAttribute("name"));


  };
  
  const handleMouseOut = () => {
    setHover(false);
  };

  const [active,setActive] = useState(false)
  return (
    <>
      {/* <nav className="cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left">
        <h3>Webions</h3>

        <div className="apnd-menu-device">
          <a className="popup-open" href="javascript:;" title="">
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i> Request
            a Quote
          </a>
        </div>
      </nav>
      <span className="mobile-nav-btn">
        <a
          href="#"
          className="toggle-menu menu-left push-body jPushMenuBtn"
        ></a>
      </span> */}
      

      <header className="main_header">
        <nav class="navbar navbar-static-top navbar-inverse bg-inverse">
          <div class="container">
            <div class="navbar-header">
              <button
                class="navbar-toggle"
                type="button"
                data-toggle="collapse"
                data-target=".navbarSupportedContent"
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <div className="logo">
                <Link to="/">
                  <img src="images/webions_logo_trans.png" alt="" />
                </Link>
              </div>
            </div>
            <div class="collapse navbar-collapse navbarSupportedContent" aria-expanded="false">
              <div className="topnavs">
                <div className="livchat-divs montfont">
                  <div className="liveChat">
                    <h4>Phone:</h4>
                    <a href="tel:+442032898634" title="">
                      <i className="fa fa-phone" aria-hidden="true"></i>+44 20
                      3289 8634
                    </a>
                  </div>
                </div>
                <div className="btn-radius-top">
                  <a className="popup-open" onClick={()=>setActive(true)}  title="" >
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
                    Request a Quote
                  </a>
                </div>
                <Modal active={active} setActive={setActive}/>
              </div>
              <div className="main-nav">
                <ul class="nav navbar-nav navbar-right montfont">
                  <li className={`parenturl home`}>
                    <Link to="/" title="">
                      <img src="images/home.png" alt="" />
                    </Link>
                  </li>
                  <li className={`parenturl dropparent`}>
                    <Link to="/Main_logo" title="">
                      Logo
                      <i
                        className="fa fa-angle-down fa-6"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <div className="dropdown">
                      <div className="lstnavig">
                        <ul className="liststylss">
                          <li
                           
                            className={`always-active ${
                              !hover ? "active" : ""
                            }`}
                          >
                            <Link to="/Main_logo" title="">
                              <i className="sprite iconlogo-design"></i>
                              <span>Logo Design</span>
                            </Link>
                            <div className="innerdropdowns active-permenent">
                              <div className="listdiscret">
                                <p>
                                  Get an outstanding logo to advertise your
                                  brand extensively.{" "}
                                </p>
                                <ul className="secondinner-navs">
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Iconic{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Animated{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Typographic{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Illustrative{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="listimgs">
                                <img
                                  src="images/navgition-rightimg.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </li>
                          <li
                            onMouseOver={handleMouseOver}
                           onMouseOut={handleMouseOut}
                            className={`always-active ${
                              hover && name === "logo_1" ? "active" : ""
                            }`}
                          >
                            <a
                              name="logo_1"
                              onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Main_logo"),
                                  (id = "pricing")
                                )
                              }
                            >
                              <i className="sprite iconlogo-pack"></i>
                              <span  name="logo_1">Logo Packages</span>
                            </a>
                            <div className="innerdropdowns">
                              <div className="listdiscret">
                                <p name="logo_1">
                                  No matter what you're looking for in Logo
                                  Design Services, we have a package that's
                                  right for you.{" "}
                                </p>
                                <a
                                  className="btnstarts startspackfr"
                                  onClick={()=>setActive(true)}
                                  title=""
                                  name="logo_1"
                                >
                                  Starting from
                                  <span name="logo_1" className="montfont">£59</span>
                                </a>
                              </div>
                              <div className="listimgs">
                                <img
                                  src="images/navgition-rightimg.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                           onMouseOut={handleMouseOut}
                            className={`always-active ${
                              hover && name === "logo_2" ? "active" : ""
                            }`}>
                            <a
                            name="logo_2"
                              onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Main_logo"),
                                  (id = "porfolio")
                                )
                              }
                            >
                              <i className="sprite iconlogo-port"></i>
                              <span name="logo_2">Logo Portfolio</span>
                            </a>
                            <div className="innerdropdowns" name="logo_2">
                              <div className="listdiscret" name="logo_2">
                                <p name="logo_2">
                                  We enrich the most prestigious names as our
                                  satisfied clientele.{" "}
                                </p>
                                <ul className="secondinner-navs" name="logo_2">
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                      name="logo_2"
                                    >
                                      Photography{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                      name="logo_2"
                                    >
                                      Fashion{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                      name="logo_2"
                                    >
                                      E-Commerce{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                      name="logo_2"
                                    >
                                      Real Estate{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                      name="logo_2"
                                    >
                                      Security{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                      name="logo_2"
                                    >
                                      Fitness{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="listimgs" name="logo_2">
                                <img
                                  src="images/navgition-rightimg.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                           onMouseOut={handleMouseOut}
                            className={` ${
                              hover && name === "logo_3" ? "active" : ""
                            }`} >
                            <Link name="logo_3" to="/Review" title="" >
                              <i className="sprite iconlogo-review"></i>
                              <span>Reviews</span>
                            </Link>
                            <div className="innerdropdowns" name="logo_3">
                              <div className="listdiscret" name="logo_3">
                                <p name="logo_3">
                                  Read the immense appreciation we receive from
                                  our customers.{" "}
                                </p>
                                <a className="btnstarts" href="#" title="" name="logo_3">
                                  Read Client Reviews
                                  <i
                                    className="fa fa-caret-right"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div >
                              <div className="listimgs" name="logo_3">
                                <img
                                  src="images/navgition-rightimg.jpg"
                                  alt=""
                                  name="logo_3"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`parenturl dropparent`}
                  >
                    <Link to="/Website" title="">
                      Website
                      <i
                        className="fa fa-angle-down fa-6"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <div className="dropdown">
                      <div className="lstnavig">
                        <ul className="liststylss">
                          <li
                            className={`always-active ${
                              !hover ? "active" : ""
                            }`}
                          >
                            <Link to="/Website" title="">
                              <i className="sprite iconlogo-design"></i>
                              <span>Web Development</span>
                            </Link>
                            <div className="innerdropdowns active-permenent">
                              <div className="listdiscret">
                                <p>
                                  Get an amazing website to extend your brand
                                  reach.{" "}
                                </p>
                                <ul className="secondinner-navs">
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Static{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      E-Commerce{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      B2B and B2C Portals{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      CMS{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="listimgs">
                                <img src="images/drop-web.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "web_1" ? "active" : ""
                             }`}>
                            <a
                              onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Website"),
                                  (id = "web_pricing")
                                )
                              }
                            >
                              <i name="web_1" className="sprite iconlogo-pack"></i>
                              <span name="web_1">Website Packages</span>
                            </a>
                            <div name="web_1" className="innerdropdowns">
                              <div name="web_1" className="listdiscret">
                                <p name="web_1">
                                  No matter what you're looking for in a web
                                  design, we have a package that's right for
                                  you.
                                </p>
                                <a name="web_1"
                                  className="btnstarts startspackfr"
                                  onClick={()=>setActive(true)}
                                  title=""
                                >
                                  Starting from
                                  <span name="web_1" className="montfont">£399</span>
                                </a>
                              </div >
                              <div  className="listimgs">
                                <img src="images/drop-web.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "web_2" ? "active" : ""
                             }`}>
                            <a
                              onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Website"),
                                  (id = "web_portfolio")
                                )
                              }
                            >
                              <i name="web_2" className="sprite iconlogo-port"></i>
                              <span name="web_2">Website Portfolio</span>
                            </a>
                            <div name="web_2" className="innerdropdowns">
                              <div name="web_2" className="listdiscret">
                                <p name="web_2">
                                  We enrich the most prestigious names as our
                                  satisfied clientele.
                                </p>
                                <ul name="web_2" className="secondinner-navs">
                                  <li name="web_2">
                                    <a name="web_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Photography{" "}
                                    </a>
                                  </li>
                                  <li name="web_2">
                                    <a name="web_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Fashion{" "}
                                    </a>
                                  </li>
                                  <li name="web_2">
                                    <a name="web_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Information & Technology
                                    </a>
                                  </li>
                                  <li name="web_2">
                                    <a name="web_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      E-Commerce{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="listimgs">
                                <img src="images/drop-web.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "web_3" ? "active" : ""
                             }`}>
                            <Link name="web_3" to="/Review" title="">
                              <i name="web_3" className="sprite iconlogo-review"></i>
                              <span name="web_3">Reviews</span>
                            </Link>
                            <div name="web_3" className="innerdropdowns">
                              <div name="web_3" className="listdiscret">
                                <p name="web_3">
                                  Read the immense appreciation we receive from
                                  our customers.
                                </p>
                                <a name="web_3" className="btnstarts" href="#" title="">
                                  Read Client Reviews
                                  <i name="web_3"
                                    className="fa fa-caret-right"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="listimgs">
                                <img src="images/drop-web.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className={`parenturl`}>
                    <Link to="/Nft_design">NFT-Design </Link>
                  </li>
                  <li
                    className={`parenturl dropparent`}
                  >
                    <Link to="/Animation_design" title="">
                      Animation
                      <i
                        className="fa fa-angle-down fa-6"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <div className="dropdown">
                      <div className="lstnavig">
                        <ul className="liststylss anim-drop">
                          <li
                            className={`always-active ${!hover ? "active" : ""}`}
                          >
                            <Link to="/Animation_design">
                              <i className="sprite iconlogo-design"></i>
                              <span>Animation Design</span>
                            </Link>
                            <div className="innerdropdowns active-permenent">
                              <div className="listdiscret">
                                <p>
                                  Get an interactive animated video for your
                                  brand.
                                </p>
                                <ul className="secondinner-navs">
                                  <li>
                                    <a href="#" title="">
                                      2D Character Animation
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="">
                                      2D Graphic Animation{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="">
                                      Interactive 3D Animation{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="">
                                      Whiteboard Animation
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="">
                                      Typographic Animation
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="">
                                      Explainer <br />
                                      Videos
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="listimgs">
                                <img src="images/drop-animation.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "anim_1" ? "active" : ""
                             }`}>
                            <a
                             name="anim_1"
                              onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Animation_design"),
                                  (id = "animation_pricing")
                                )
                              }
                            >
                              <i name="anim_1" className="sprite iconlogo-pack"></i>
                              <span name="anim_1">Animation Packages</span>
                            </a>
                            <div name="anim_1" className="innerdropdowns">
                              <div name="anim_1" className="listdiscret">
                                <p name="anim_1">
                                  No matter what you're looking for in an
                                  animated video, we have a package that's right
                                  for you.
                                </p>
                                <a name="anim_1"
                                  className="btnstarts startspackfr"
                                  onClick={()=>setActive(true)}
                                  title=""
                                >
                                  Starting from
                                  <span name="anim_1" className="montfont">£499</span>
                                </a>
                              </div>
                              <div  className="listimgs">
                                <img src="images/drop-animation.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "anim_2" ? "active" : ""
                             }`}>
                            <a name="anim_2"
                              onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Animation_design"),
                                  (id = "animdropsPort")
                                )
                              }
                            >
                              <i name="anim_2" className="sprite iconlogo-port"></i>
                              <span name="anim_2">Animation Portfolio</span>
                            </a>
                            <div name="anim_2" className="innerdropdowns">
                              <div name="anim_2" className="listdiscret">
                                <p name="anim_2">
                                  We enrich the most prestigious names as our
                                  satisfied clientele.
                                </p>
                                <ul name="anim_2" className="secondinner-navs">
                                  <li name="anim_2">
                                    <a name="anim_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Photography{" "}
                                    </a>
                                  </li>
                                  <li name="anim_2">
                                    <a name="anim_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Fashion{" "}
                                    </a>
                                  </li>

                                  <li  name="anim_2">
                                    <a name="anim_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Information & Technology
                                    </a>
                                  </li>
                                  <li name="anim_2">
                                    <a name="anim_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      E-Commerce{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div  className="listimgs">
                                <img src="images/drop-animation.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "anim_3" ? "active" : ""
                             }`}>
                            <Link to="/Review" name="anim_3">
                              <i name="anim_3" className="sprite iconlogo-review"></i>
                              <span name="anim_3">Reviews</span>
                            </Link>
                            <div name="anim_3" className="innerdropdowns">
                              <div name="anim_3" className="listdiscret">
                                <p name="anim_3">
                                  Read the immense appreciation we receive from
                                  our customers.
                                </p>
                                <a name="anim_3" className="btnstarts" href="#" title="">
                                  Read Client Reviews
                                  <i name="anim_3"
                                    className="fa fa-caret-right"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="listimgs">
                                <img src="images/drop-animation.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`parenturl dropparent`}
                  >
                    <Link to="/Branding_design" title="">
                      Branding
                      <i
                        className="fa fa-angle-down fa-6"
                        aria-hidden="true"
                      ></i>
                    </Link >
                    <div className="dropdown">
                      <div className="lstnavig">
                        <ul className="liststylss">
                          <li
                             className={`always-active ${!hover ? "active" : ""}`}
                          >
                            <Link to="/Branding_design">
                              <i className="sprite iconlogo-design"></i>
                              <span>Branding Design</span>
                            </Link>
                            <div className="innerdropdowns active-permenent">
                              <div className="listdiscret">
                                <p>
                                  Get complete branding solutions for your
                                  brand.
                                </p>
                                <ul className="secondinner-navs">
                                  <li>
                                    <a href="#" title="">
                                      Brochures{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="">
                                      Banners{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="">
                                      Stationery{" "}
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="">
                                      Packaging{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="listimgs">
                                <img src="images/drop-branding.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "brand_1" ? "active" : ""
                             }`}>
                            <a name = "brand_1"
                              onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Branding_design"),
                                  (id = "packdropsbrand")
                                )
                              }
                            >
                              <i name = "brand_1" className="sprite iconlogo-pack"></i>
                              <span name = "brand_1">Branding Packages</span>
                            </a>
                            <div name = "brand_1" className="innerdropdowns">
                              <div name = "brand_1" className="listdiscret">
                                <p name = "brand_1">
                                  No matter what you're looking for in Logo
                                  Design Services, we have a package that's
                                  right for you.{" "}
                                </p>
                                <a name = "brand_1"
                                  className="btnstarts startspackfr"
                                  onClick={()=>setActive(true)}
                                  title=""
                                >
                                  Starting from
                                  <span name = "brand_1" className="montfont">£249</span>
                                </a>
                              </div>
                              <div  className="listimgs">
                                <img src="images/drop-branding.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li  onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "brand_2" ? "active" : ""
                             }`}>
                            <a
                              onClick={(ref, id) =>
                                scrollTo(
                                  (ref = "/Branding_design"),
                                  (id = "branding_portfolio")
                                )
                              }
                            >
                              <i name="brand_2" className="sprite iconlogo-port"></i>
                              <span name="brand_2">Branding Portfolio</span>
                            </a>
                            <div name="brand_2" className="innerdropdowns">
                              <div name="brand_2" className="listdiscret">
                                <p name="brand_2">
                                  Exclusive brochures for enhanced advertising.{" "}
                                </p>
                                <ul name="brand_2" className="secondinner-navs">
                                  <li name="brand_2">
                                    <a name="brand_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Leaflet{" "}
                                    </a>
                                  </li>
                                  <li name="brand_2">
                                    <a name="brand_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Bifold{" "}
                                    </a>
                                  </li>
                                  <li name="brand_2">
                                    <a name="brand_2"
                                      className="no-src"
                                      href="javascript:;"
                                      title=""
                                    >
                                      Trifold{" "}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="listimgs">
                                <img src="images/drop-branding.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                          <li onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                             className={`always-active ${
                               hover && name === "brand_3" ? "active" : ""
                             }`}>
                            <Link name="brand_3" to="/Review">
                              <i name="brand_3" className="sprite iconlogo-review"></i>
                              <span name="brand_3">Reviews</span>
                            </Link>
                            <div name="brand_3" className="innerdropdowns">
                              <div name="brand_3" className="listdiscret">
                                <p name="brand_3">
                                  Read the immense appreciation we receive from
                                  our customers.
                                </p>
                                <a name="brand_3" className="btnstarts" href="#" title="">
                                  Read Client Reviews
                                  <i name="brand_3"
                                    className="fa fa-caret-right"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="listimgs">
                                <img src="images/drop-branding.jpg" alt="" />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className={`parenturl`}>
                    <Link to="/Packages" title="">
                      Packages
                    </Link>
                  </li>
                  <li className={`parenturl`}>
                    <Link to="/Portfoli" title="">
                      Portfolio
                    </Link>
                  </li>
                  <li className={`parenturl`}>
                    <Link to="/About">About Us </Link>
                  </li>
                  <li className={`parenturl`}>
                    <Link to="/Contact_main" title="">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
